<template>
    <div class="slider-container" style="border: 2px solid white;background:#144967">
        <!-- SLIDE 1 -->
        <div class="slide" :class="{ active: 0 === currentSlide }">
            <img src="@/static/img_assets/home-1.png" alt="" class="slide-image" />
            <div class="slide-content">
                <div class="my-auto">
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        We Are
                    </div>
                    <img src="@/static/logo - Copy.png" class="" style="max-width:350px" />
                    <div class="h2 text-white mb-0 mt-3">
                        We are the pet super apps platform created for pet lovers.
                        <br><br>
                        Whether you own a pet, love to extend your service for pets and owner or simply you have a big
                        heart
                        that want to help volunteer with pet and community shelters.
                    </div>
                </div>
                <div class="arrows">
                    <span class="arrow left-arrow" @click="prevSlide">&#8592;</span>
                    <span class="arrow right-arrow" @click="nextSlide">&#8594;</span>
                </div>
            </div>
        </div>
        <!-- END SLIDE -->
        <!-- SLIDE 2 -->
        <div class="slide" :class="{ active: 1 === currentSlide }">
            <div class="position-relative h-100 w-100">
                <div class="position-absolute d-flex flex-column justify-content-center px-3 h-100" style="gap:10px">
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        In
                    </div>
                    <img src="@/static/logo - Copy.png" class="" style="max-width:350px" />
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        We <br> Believed
                    </div>
                </div>
                <img src="@/static/img_assets/home-2.png" alt="" class="slide-image" />
            </div>
            <div class="slide-content">
                <div class="my-auto">
                    <div class="h2 text-white mb-0 mt-3">
                        We are the pet super apps platform
                        created for pet lovers. Whether you own a pet, love to extend your service
                        for pets and owner or simply you have
                        a big heart that want to help volunteer
                        with pet and community shelters.
                    </div>
                </div>
                <div class="arrows">
                    <span class="arrow left-arrow" @click="prevSlide">&#8592;</span>
                    <span class="arrow right-arrow" @click="nextSlide">&#8594;</span>
                </div>
            </div>
        </div>
        <!-- END SLIDE -->
        <!-- SLIDE 3 -->
        <div class="slide" :class="{ active: 2 === currentSlide }">
            <div class="position-relative h-100 w-100">
                <div class="position-absolute d-flex flex-column justify-content-center px-3 h-100" style="gap:10px">
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        In
                    </div>
                    <img src="@/static/logo - Copy.png" class="" style="max-width:350px" />
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        We <br> Valued
                    </div>
                </div>
                <img src="@/static/img_assets/home-3.png" alt="" class="slide-image" />
            </div>
            <div class="slide-content">
                <div class="my-auto">
                    <div class="h2 text-white mb-0 mt-3">
                        They were no longer judged by their
                        situation but embraced with
                        understanding. Shelters became
                        safe havens, education spread, and
                        love replaced prejudice.
                        Every life mattered, reflecting our
                        empathy and shared belief in the
                        value of all creatures.
                    </div>
                </div>
                <div class="arrows">
                    <span class="arrow left-arrow" @click="prevSlide">&#8592;</span>
                    <span class="arrow right-arrow" @click="nextSlide">&#8594;</span>
                </div>
            </div>
        </div>
        <!-- END SLIDE -->
        <!-- SLIDE 4 -->
        <div class="slide" :class="{ active: 3 === currentSlide }">
            <div class="position-relative h-100 w-100">
                <div class="position-absolute d-flex flex-column justify-content-center px-3 h-100" style="gap:10px">
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        In
                    </div>
                    <img src="@/static/logo - Copy.png" class="" style="max-width:350px" />
                    <div style="color: #FFAD32;" class="display-2 fw-bolder mb-0">
                        We <br> Acted
                    </div>
                </div>
                <img src="@/static/img_assets/home-4.png" alt="" class="slide-image" />
            </div>
            <div class="slide-content">
                <div class="my-auto">
                    <div class="h2 text-white mb-0 mt-3">
                        In our caring world, we prioritize the
                        well-being of stray cats and dogs. We
                        ensure they receive proper healthcare
                        and attention.
                        Their health and happiness are of
                        utmost importance to us.
                    </div>
                </div>
                <div class="arrows">
                    <span class="arrow left-arrow" @click="prevSlide">&#8592;</span>
                    <span class="arrow right-arrow" @click="nextSlide">&#8594;</span>
                </div>
            </div>
        </div>
        <!-- END SLIDE -->

        <!-- Indicators -->
        <div class="indicator-container">
            <div class="indicator" :class="{ active: 0 === currentSlide }" @click="goToSlide(0)"></div>
            <div class="indicator" :class="{ active: 1 === currentSlide }" @click="goToSlide(1)"></div>
            <div class="indicator" :class="{ active: 2 === currentSlide }" @click="goToSlide(2)"></div>
            <div class="indicator" :class="{ active: 3 === currentSlide }" @click="goToSlide(3)"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return { 
            currentSlide: 0,
            autoSlideInterval: null, // Store the interval reference here
        };
    },
    mounted() {
        this.startAutoSlide(); // Start auto-slide when component is mounted
    },
    beforeDestroy() {
        this.stopAutoSlide(); // Stop auto-slide when component is destroyed
    },
    methods: {
        startAutoSlide() {
            this.autoSlideInterval = setInterval(() => {
                this.nextSlide();
            }, 4000); // Change slide every 5 seconds (5000 ms)
        },
        stopAutoSlide() {
            clearInterval(this.autoSlideInterval); // Clear the interval
        },
        resetAutoSlide() {
            this.stopAutoSlide();
            this.startAutoSlide(); // Restart the auto-slide timer
        },
        nextSlide() {
            this.currentSlide = (this.currentSlide + 1) % 4;
            this.resetAutoSlide(); // Reset timer on manual change
        },
        prevSlide() {
            this.currentSlide = (this.currentSlide - 1 + 4) % 4;
            this.resetAutoSlide();
        },
        goToSlide(index) {
            this.currentSlide = index;
            this.resetAutoSlide();
        },
    },
};
</script>

<style scoped>
.slider-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 15px;
}

.slide {
    position: absolute;
    top: 100%; /* Start below the view */
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: top 0.8s ease-in-out, opacity 0.8s ease-in-out;
}

.slide.active {
    top: 0; /* Move into view */
    opacity: 1;
    z-index: 1;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-color: rgba(20, 73, 103, .6);
    /* background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(20, 73, 103, .6) 2%); */
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
.arrows {
    display: flex;
    justify-content: space-between;
}

.arrow {
    font-size: 1.5em;
    color: #FFAD32;
    cursor: pointer;
}

.indicator-container {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    z-index: 99;
    flex-direction: column;
    gap: 10px;
}

.indicator {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    transition: background-color 0.3s, transform 0.3s;
}

.indicator.active {
    background-color: #FFAD32;
    opacity: 1;
    transform: scale(1.2);
}
</style>
